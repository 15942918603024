<template>
  <CContainer class="text-black">
    <div class="row">
      <div class="col text-left">
        <a @click="goback()">
          <CIcon name="cil-chevron-left" size="lg"></CIcon>
        </a>
      </div>
      <div class="col text-center mt-1">
        <h6 class="font-weight-bold">ที่อยู่</h6>
      </div>
      <div class="col text-right">
        <router-link to="/menu/order" class="text-black">
          <span style="padding: 10px">
            <CIcon name="cil-x" size="lg"></CIcon>
          </span>
        </router-link>
      </div>
    </div>
    <hr />

    <div class="mb-3">
      <label class="form-label h6 font-weight-bold" style="font-size: 12px">
        ชื่อ-สกุล ผู้รับ <span class="text-danger">*</span>
      </label>
      <input
        type="email"
        class="form-control"
        placeholder="ชื่อ-สกุล"
        v-model="name"
      />
    </div>
    <div class="mb-3">
      <label class="form-label h6 font-weight-bold" style="font-size: 12px">
        ที่อยู่ <span class="text-danger">*</span>
      </label>
      <textarea
        class="form-control"
        rows="3"
        placeholder="ที่อยู่"
        v-model="address"
      ></textarea>
    </div>
    <div class="mb-3">
      <label class="form-label h6 font-weight-bold" style="font-size: 12px">
        จังหวัด <span class="text-danger">*</span>
      </label>
      <input
        type="text"
        class="form-control"
        placeholder="จังหวัด"
        v-model="province"
      />
    </div>
    <div class="mb-3">
      <label class="form-label h6 font-weight-bold" style="font-size: 12px">
        รหัสไปรษณีย์ <span class="text-danger">*</span>
      </label>
      <input
        type="text"
        class="form-control"
        placeholder="รหัสไปรษณีย์"
        v-model="postalCode"
      />
    </div>
    <div class="mb-3">
      <label class="form-label h6 font-weight-bold" style="font-size: 12px">
        เบอร์โทรศัพท์ <span class="text-danger">*</span>
      </label>
      <input
        type="text"
        class="form-control"
        placeholder="เบอร์โทรศัพท์"
        v-model="phone"
      />
    </div>

    <div class="mb-3">
      <label class="form-label h6 font-weight-bold" style="font-size: 12px">
        อีเมล์
      </label>
      <input
        type="text"
        class="form-control"
        placeholder="อีเมล์"
        v-model="email"
      />
    </div>
    <hr />
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        value="true"
        v-model="isSaved"
      />
      <label class="form-check-label mt-1" style="font-size: 12px">
        บันทึกที่อยู่สำหรับการสั่งซื้อในครั้งถัดไป
      </label>
    </div>
    <br />

    <button class="btn btn-block btn-success mb-5">เพิ่มที่อยู่จัดส่ง</button>
  </CContainer>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      address: '',
      province: '',
      postalCode: '',
      phone: '',
      email: '',
      isSaved: false,
    }
  },
  methods: {
    goback() {
      window.history.back()
    },
  },
}
</script>
